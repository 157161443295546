import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Layout } from '@shared/utils/layout';

export function styles({ spacing, breakpoints }: AppTheme) {
  return appCreateStyles({
    container: {
      width: '100%',
      marginTop: spacing(8),
      padding: '0px 14px',
      '& .line-chart-view': {
        minHeight: '390px',
        minWidth: '100%',
        marginLeft: '-4%',
      },
    },
    chartHeader: {
      display: 'flex',
    },
    h2: {
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 500,
      [breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        fontSize: '16px',
        fontWeight: 400,
      },
    },
    controls: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',
      [breakpoints.up(Layout.laptop)]: {
        position: 'absolute',
        right: '20px',
        top: '-5px',
      },
    },
    box: {},
    loader: {
      width: '100%',
      height: '237px',
      display: 'flex',
      justifyContent: 'center',
    },
  });
}
