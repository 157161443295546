import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import DesktopDashboard from '@modules/new-private/dashboard/components/desktop-dashboard';
import MobileDashboard from '@modules/new-private/dashboard/components/mobile-dashboard';
import { Theme, useMediaQuery } from '@mui/material';
import { Layout } from '@shared/utils/layout';

import { styles } from './dashboard.styles';

export interface DashboardProps extends AppWithStyles<typeof styles> {}

const Dashboard: React.FC<DashboardProps> = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  if (isMobile) {
    return <MobileDashboard />;
  } else {
    return <DesktopDashboard />;
  }
};

export default appWithStyles(styles)(Dashboard);
