import React, { useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DashboardViewModel } from '@modules/new-private/dashboard/dashboard.vm';
import { AppSelect } from '@shared/components/new-design/app-select/app-select';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { reaction } from 'mobx';

import HexagoneSvg from './img/hexagone.svg';

import { styles } from './account-value.styles';

export interface AccountValueProps extends AppWithStyles<typeof styles> {
  vm: DashboardViewModel;
}

const AccountValueComponent: React.FC<AccountValueProps> = appObserver(({ classes, vm }) => {
  const $vm = useMemo(() => vm, []);

  reaction(
    () => $vm.activeCurrency,
    () => $vm.calculateTotalAmount(),
  );

  const handleChangeCurrency = (newValue: string | number) => {
    $vm.setActiveCurrency(newValue as string);
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleBlock}>
        <div className={classes.title}>{t`Account Value`}</div>
        <div className={classes.currencySwitcher}>
          <AppSelect
            options={$vm.currencies}
            defaultOption={$vm.activeCurrency}
            onChangeItem={handleChangeCurrency}
            size={'small'}
            minWidth={67}
          />
        </div>
      </div>
      <div className={classes.totalAmount}>
        <div className={classes.amount}>$ {$vm.totalAmount}</div>{' '}
      </div>
      <ul className={classes.percentage}>
        {$vm.investmentsRatio
          .sort(
            (prev, next) =>
              $vm.getProductPriorityByCrrency(prev.currency) -
              $vm.getProductPriorityByCrrency(next.currency),
          )
          .map((item) => {
            return (
              <li className={classes.percentageItem}>
                <img src={String(HexagoneSvg)} />
                <div className={classes.productName}>{item.currency}: </div>
                <div className={classes.percent}>
                  {minDigitsAfterDot(amountPipe(item.percentage, 1), 1)}%
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
});

export default appWithStyles(styles)(AccountValueComponent);
