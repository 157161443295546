import React from 'react';

import { appInject } from '@core/di/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DI_TOKENS } from '@shared/constants/di';
import { ProductType } from '@shared/models/products/product-model';
import { TransactionType } from '@shared/models/transactions/transaction-type';
import { RecentActivityQuery } from '@shared/models/wallets/recent-activities';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { transactionTypePipe } from '@shared/pipes/transaction-type.pipe';
import { IProductsVM } from '@shared/types/products-vm';
import { formatDate } from '@shared/utils/date';
import classNames from 'classnames';

import { styles } from './activity-item.styles';

interface Props extends AppWithStyles<typeof styles> {
  data: RecentActivityQuery;
}

const ActivityItemComponent: React.FC<Props> = ({ classes, data }) => {
  const { date, transactionType, value, currency } = data;
  const productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  const productType = productsVM.getProductTypeByCurrency(currency);
  const statusForTransactionType = (): string => {
    const successClass: Record<string, string | undefined> = {
      [TransactionType.PURCHASED]: classes.successPurchased,
      [TransactionType.SOLD]: classes.successPurchased,
      [TransactionType.SENT]: classes.successReceived,
      [TransactionType.RECEIVED]: classes.successReceived,
      [TransactionType.DEPOSIT]: classes.successDeposited,
      [TransactionType.SPENT]: classes.successCachedOut,
      [TransactionType.EARNED]: classes.successCachedOut,
      [TransactionType.WITHDRAWAL]: classes.successCachedOut,
    };

    return successClass[transactionType] || '';
  };

  let direction;
  switch (true) {
    case transactionType === TransactionType.SENT:
    case transactionType === TransactionType.SPENT:
    case transactionType === TransactionType.SOLD:
    case transactionType === TransactionType.WITHDRAWAL:
      direction = false;
      break;
    default:
      direction = true;
      break;
  }

  const directionClass = classNames({
    [classes.debit as string]: direction,
    [classes.credit as string]: !direction,
  });

  return (
    <div className={classes.item}>
      <div className={classes.row}>
        <span className={classNames(classes.status, statusForTransactionType())}>
          {transactionTypePipe(transactionType)}
        </span>
        <span className={directionClass}>
          {direction ? '+' : '-'}
          {productType === ProductType.SMART_COIN
            ? minDigitsAfterDot(amountPipe(value, 8))
            : minDigitsAfterDot(amountPipe(value, 2))}{' '}
          {currency as string}
        </span>
      </div>
      <div className={classes.row}>
        <span className={classes.date}>{formatDate(date, t`MMM D, YYYY [at] h:mm A`)}</span>
      </div>
    </div>
  );
};

export const ActivityItem = appWithStyles(styles)(ActivityItemComponent);
