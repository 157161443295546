import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { Currency } from '@shared/models/wallets/currency';
import { RecentActivitiesModel } from '@shared/models/wallets/recent-activities';
import { IRatesVM } from '@shared/types/rates-vm';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { IWalletsService } from '@shared/types/wallets-service';

export class RecentActivitiesViewModel {
  private walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  private _recentActivitiesList: RecentActivitiesModel[] = [];
  private smartCoinTracinkService = appInject<ISmartCoinTrackingService>(
    DI_TOKENS.smartCoinTrackingService,
  );
  get currency(): Currency {
    return this.ratesVM.currency;
  }

  get recentActivitiesList(): RecentActivitiesModel[] {
    return this._recentActivitiesList;
  }

  get noActivitiesData(): boolean {
    return this.recentActivitiesList.length === 0;
  }

  subscribeForOrders(cb: () => void) {
    this.smartCoinTracinkService.subscribeForExecutedOrder('recentActivities', cb);
  }

  async downloadRecentActivities() {
    try {
      this._recentActivitiesList = await this.walletsService.getRecentActivities();
      this._recentActivitiesList = this._recentActivitiesList.filter((a, index) => index < 10);
    } catch (e) {}
  }
}
