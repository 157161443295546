import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      padding: '46px 20px 20px 20px',
      width: '100%',
      [breakpoints.up(Layout.laptop)]: {
        padding: '30px 20px 20px 20px',
      },
    },
    title: {
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '19px',
      position: 'relative',
      [breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        fontSize: '16px',
        fontWeight: 400,
      },
    },
    allActivityButton: {
      borderRadius: '15px',
      background: 'rgba(255, 255, 255, 0.08)',
      width: '92px',
      height: '30px',
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '19px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'absolute',
      top: '-5px',
      right: '-8px',
    },
    list: {
      marginTop: '16px',
      borderRadius: 4,
      minHeight: '200px',
      height: 'auto',
      [breakpoints.up(Layout.laptop)]: {
        height: `calc(${_var(CSS_VARIABLES.appHeight)} - 543px)`,
        overflow: 'auto',
        marginBottom: '10px',
        minHeight: '0px',
      },
    },
    loader: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    show: {
      marginTop: '46px',
    },
  });
}
