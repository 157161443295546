import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { UserRestrictionReasonsEnum } from '@shared/enums/user-restriction-reasons.enum';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { NotificationListModel } from '@shared/models/notifications/list-model';
import { ProductType } from '@shared/models/products/product-model';
import { BlockchainWalletModel } from '@shared/models/wallets/blockchain-wallet';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { IConfigService } from '@shared/types/config-service';
import { ILayoutVM } from '@shared/types/layout-service';
import { INotificationsHandler } from '@shared/types/notifications-handler';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesVM } from '@shared/types/rates-vm';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { IUsersService } from '@shared/types/users-service';
import { IWalletsService } from '@shared/types/wallets-service';

export class DashboardViewModel implements INotificationsHandler {
  private layoutVM = appInject<ILayoutVM>(DI_TOKENS.layoutVM);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  private _wallets: Array<WalletsListModel> = [];
  private _notifications: NotificationListModel[] = [];
  private smartCoinTracinkService = appInject<ISmartCoinTrackingService>(
    DI_TOKENS.smartCoinTrackingService,
  );
  private _blockchainWallet: any = new BlockchainWalletModel({
    id: '',
    publicKey: '',
    balance: 0,
  });
  private _userStatus: any = {
    data: null,
  };

  private _restrictions = {
    data: <UserRestrictionReasonsEnum | null>UserRestrictionReasonsEnum.CHECK_IN_PROGRESS,
  };
  private _loading = {
    products: false,
    wallets: false,
    restrictions: false,
  };
  private _error = {
    walletsGet: false,
  };

  constructor() {
    appMakeObservable(this, {
      _wallets: appObservable,
      _loading: appObservable,
      _error: appObservable,
      _restrictions: appObservable,
      _blockchainWallet: appObservable,
      _userStatus: appObservable,
    });

    this.getWallets();
  }

  get wallets() {
    return this._wallets;
  }

  get restrictions() {
    return this._restrictions;
  }

  get loading() {
    return this._loading;
  }

  get error() {
    return this._error;
  }

  get blockchainWalletLink() {
    return `${this.configService.blockchainViewerOrigin}/transactions?search=${this._blockchainWallet.publicKey}`;
  }

  subscribeForOrders(cb: () => void) {
    this.smartCoinTracinkService.subscribeForExecutedOrder('mobileDashboard', cb);
  }

  get rate(): number {
    return this.ratesVM.rate[this.currency] as number;
  }

  get currency() {
    return this.ratesVM.currency;
  }

  getWallets = async () => {
    try {
      this._error.walletsGet = false;
      this._loading.wallets = true;
      this._wallets = await this.walletsService.getList(this.ratesVM.currency, true);
    } catch (err) {
      this._error.walletsGet = true;
      console.error(err);
    } finally {
      this._loading.wallets = false;
    }
  };

  releaseNotifications = () => {
    this._notifications.map((n) => this.layoutVM.removeNotification(n.asJson.id));
  };

  checkUserStatus = async () => {
    this._loading.restrictions = true;
    try {
      const user = await this.usersService.getMyUserDetails();
      this._userStatus.data = user.asJson.userStatus;
      this._restrictions.data = user.restrictions;
    } catch (e: HttpErrorResponse | any) {
      console.error(e.message);
    }
    this._loading.restrictions = false;
  };

  getBlockchainWallet = async () => {
    try {
      this._blockchainWallet = await this.walletsService.getBlockchainWallets();
    } catch (e) {
      console.error(e);
    }
  };

  isFiatWallet(wallet: WalletsListModel) {
    return (
      this.productsVM.getProductTypeByCurrency(wallet.asJson.currency) === ProductType.FIAT_COIN
    );
  }
}
