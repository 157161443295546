import React, { useMemo, useState } from 'react';

import { appInject } from '@core/di/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DashboardViewModel } from '@modules/new-private/dashboard/dashboard.vm';
import { WalletsDialog } from '@modules/new-private/wallets/top-up/components/wallets-dialog';
import { Theme, useMediaQuery } from '@mui/material';
import { KYCDialogDialog } from '@shared/components/new-design/kyc-dialog/kyc-dialog';
import { VerificationDialogDialog } from '@shared/components/new-design/verification-dialog/verification-dialog';
import { useNavigate } from '@shared/components/router';
import { DI_TOKENS } from '@shared/constants/di';
import { ROUTES } from '@shared/constants/routes';
import { ProductModel, ProductType } from '@shared/models/products/product-model';
import { SmartCoinOrderType } from '@shared/models/smart-coin-order-status/smart-coin-order-type';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { IProductsVM } from '@shared/types/products-vm';
import { Layout } from '@shared/utils/layout';

import { BuyButton, SellButton, SellGoldButton, styles, TopUpButton } from './actions.styles';

export interface ActionsProps extends AppWithStyles<typeof styles> {
  wallets: Array<WalletsListModel>;
  vm: DashboardViewModel;
}

const ActionsComponent: React.FC<ActionsProps> = ({ classes, wallets, vm }) => {
  const navigate = useNavigate();
  const [isOpenWalletsDialog, setIsOpenWalletsDialog] = useState<boolean>(false);
  const [isOpenVerificationDialog, setIsOpenVerificationDialog] = useState<boolean>(false);
  const [isOpenKYCDialog, setIsOpenKYCDialog] = useState<boolean>(false);
  const [redirectRoute, setRedirectRoute] = useState<string>('');
  const onCloseKYCDialog = () => {};
  const productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.laptop));
  const $vm = useMemo(() => vm, []);

  const onCloseVerificationDialog = (result: boolean) => {
    if (result) {
      if (isMobile) {
        navigate(ROUTES.verification, { state: { redirect: redirectRoute } });
      } else {
        setIsOpenKYCDialog(true);
      }
    }
    setIsOpenVerificationDialog(false);
  };

  const handleClickBuyButton = () => {
    const route = isMobile
      ? ROUTES.mobilePrivate.wallets.children.order(SmartCoinOrderType.PURCHASE, Currency.GSC)
      : ROUTES.desktopPrivate.wallets.children.order(SmartCoinOrderType.PURCHASE, Currency.GSC);
    if ($vm.isNeedKYC) {
      setRedirectRoute(route);
      setIsOpenVerificationDialog(true);
    } else {
      navigate(route);
    }
  };

  const handleClickSellButton = () => {
    const route = isMobile
      ? ROUTES.mobilePrivate.wallets.children.order(SmartCoinOrderType.SELL, Currency.GSC)
      : ROUTES.desktopPrivate.wallets.children.order(SmartCoinOrderType.SELL, Currency.GSC);
    if ($vm.isNeedKYC) {
      setRedirectRoute(route);
      setIsOpenVerificationDialog(true);
    } else {
      navigate(route);
    }
  };

  const handleClickDeposit = () => {
    const route = isMobile ? ROUTES.mobilePrivate.topUp : ROUTES.desktopPrivate.wallets.topUp;
    if ($vm.isNeedKYC) {
      setRedirectRoute(route);
      setIsOpenVerificationDialog(true);
    } else {
      navigate(route);
    }
  };

  const handleClickWithdraw = () => {
    setIsOpenWalletsDialog(true);
  };

  const handleSelectWallet = (walletId: string) => {
    const wallet = wallets.find((w) => w.asJson.id === walletId) as WalletsListModel;
    const product = productsVM.getProductByCurrency(wallet.asJson.currency) as ProductModel;
    const orderType =
      product.asJson.type === ProductType.SMART_COIN
        ? SmartCoinOrderType.SELL
        : SmartCoinOrderType.SELL_FIAT_TOKEN;
    isMobile
      ? navigate(ROUTES.mobilePrivate.wallets.children.order(orderType, product.asJson.name))
      : navigate(ROUTES.desktopPrivate.wallets.children.order(orderType, product.asJson.name));
  };

  const walletsDialog = (
    <WalletsDialog
      title={t`Choose Account to Withdraw`}
      description={''}
      isOpen={isOpenWalletsDialog}
      metals={[]}
      wallets={wallets
        .filter(
          (w) => productsVM.getProductTypeByCurrency(w.asJson.currency) === ProductType.FIAT_COIN,
        )
        .sort(
          (prev, next) =>
            (productsVM.getProductByCurrency(next.asJson.currency)?.asJson.viewPriority || 0) -
            (productsVM.getProductByCurrency(prev.asJson.currency)?.asJson.viewPriority || 0),
        )}
      onClose={() => setIsOpenWalletsDialog(false)}
      onSelectWallet={handleSelectWallet}
    />
  );

  if (isMobile) {
    return (
      <div className={classes.mobile}>
        <div className={classes.button}>
          <TopUpButton onClick={() => handleClickDeposit()} title={t`Deposit`} />
        </div>
        <div className={classes.button}>
          <BuyButton onClick={() => handleClickBuyButton()} title={t`Buy Gold`} />
        </div>
        <div className={classes.button}>
          <SellGoldButton onClick={() => handleClickSellButton()} title={t`Sell Gold`} />
        </div>
        <div className={classes.button}>
          <SellButton onClick={() => handleClickWithdraw()} title={t`Withdraw`} />
        </div>
        {walletsDialog}
        <VerificationDialogDialog
          isOpen={isOpenVerificationDialog}
          onClose={onCloseVerificationDialog}
        />
        <KYCDialogDialog isOpen={isOpenKYCDialog} onClose={onCloseKYCDialog} />
      </div>
    );
  } else {
    return (
      <div className={classes.desktop}>
        <div className={classes.flex}>
          <TopUpButton onClick={() => handleClickDeposit()} title={t`Deposit`} />
          <BuyButton onClick={() => handleClickBuyButton()} title={t`Buy Gold`} />
        </div>
        <div className={classes.flex}>
          <SellGoldButton onClick={() => handleClickSellButton()} title={t`Sell Gold`} />
          <SellButton onClick={() => handleClickWithdraw()} title={t`Withdraw`} />
        </div>
        {walletsDialog}
        <VerificationDialogDialog
          isOpen={isOpenVerificationDialog}
          onClose={onCloseVerificationDialog}
        />
        <KYCDialogDialog isOpen={isOpenKYCDialog} onClose={onCloseKYCDialog} />
      </div>
    );
  }
};

export default appWithStyles(styles)(ActionsComponent);
