import React, { useEffect, useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { NoData } from '@shared/components/no-data';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';
import { useQuery } from 'react-query';

import { ActivityItem } from './components/activity-item';
import { RecentActivitiesViewModel } from './recent-activities.vm';

import { styles } from './recent-activities.styles';

interface Props extends AppWithStyles<typeof styles> {}

const RecentActivityComponent: React.FC<Props> = appObserver(({ classes: c }) => {
  const $vm = useMemo(() => new RecentActivitiesViewModel(), []);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const navigate = useNavigate();

  const handleSeeAllActivitiesButton = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.activity)
      : navigate(ROUTES.desktopPrivate.wallets.root);
  };

  const downloadRecentActivitiesQuery = useQuery(
    ['download-recent-activities-query', $vm.currency],
    () => $vm.downloadRecentActivities(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  useEffect(() => {
    $vm.subscribeForOrders(() => {
      downloadRecentActivitiesQuery.refetch();
    });
  }, []);

  return (
    <div className={c.root}>
      <h3 className={c.title}>
        {t`Recent activity`}
        <div
          className={c.allActivityButton}
          onClick={handleSeeAllActivitiesButton}
        >{t`All activity`}</div>
      </h3>
      <div className={c.list}>
        {downloadRecentActivitiesQuery.isFetching && (
          <div className={c.loader}>
            <Loading size={30} />
          </div>
        )}
        {!downloadRecentActivitiesQuery.isFetching &&
          $vm.recentActivitiesList.map((item) => <ActivityItem data={item.asJson} />)}
        {!downloadRecentActivitiesQuery.isFetching && $vm.noActivitiesData ? (
          <NoData height="100%" text={t`It looks like You still have no activities`} />
        ) : (
          <></>
        )}
      </div>
      <SubmitButton
        label={t`See all activity`}
        className={c.show}
        disabled={downloadRecentActivitiesQuery.isFetching || $vm.noActivitiesData}
        isLoading={false}
        onSubmit={handleSeeAllActivitiesButton}
      />
    </div>
  );
});

export const RecentActivities = appWithStyles(styles)(RecentActivityComponent);
