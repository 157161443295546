import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      height: 'fit-content',
      width: '100%',
      paddingTop: '31px',
      paddingBottom: '20px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    maintenanceLabel: {
      width: '100%',
      height: 'fit-content',
      marginBottom: '25px',
    },
  });
}
