import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles({ spacing }: AppTheme) {
  return appCreateStyles({
    root: {
      padding: spacing(6, 6, 4),
      height: '100%',
      '& .line-chart-view': {
        marginLeft: '0px',
        minHeight: 'calc(100vh - 485px)',
      },
      '& .loader': {
        minHeight: 'calc(100vh - 485px)',
      },
    },
    summary: {
      paddingTop: spacing(6),
      fontWeight: 400,
      fontSize: '16px',
      color: '#fff',

      '& span': {
        fontWeight: 600,
      },
    },
    chart: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 'calc(100% - 300px)',
    },
    box: {
      marginRight: spacing(6),
      width: 'calc(100% - 285px)',
      position: 'relative',
    },
    recentActivities: {
      width: 260,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
  });
}
