import React from 'react';

import { appCreateStyles } from '@core/theme/utils/create-styles';
import { IconButton, Theme } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { withStyles } from '@mui/styles';
import { AppSvgIcon } from '@shared/components/svg-icon';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    mobile: {
      marginTop: '30px',
      width: '100%',
      display: 'flex',
      padding: '0px 15px',
      color: '#FFF',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
    },
    desktop: {
      marginTop: '30px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      padding: '0px 15px',
      color: '#FFF',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-around',
      '& + &': {
        marginTop: '30px',
      },
    },
    walletsList: {
      width: '100%',
      height: '250px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      color: '#F7F9FC',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginBottom: '40px',
    },
    wallet: {
      height: '65px',
      cursor: 'pointer',
      width: '80%',
      '& + &': {
        marginTop: '20px',
      },
    },
    button: {
      margin: '0px auto',
    },
  });
}

const StyledIconButton = withStyles(() => ({
  root: {
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    background: '#2D2B56 !important',
  },
}))(IconButton);

const PurpleStyledIconButton = withStyles(() => ({
  root: {
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    background: '#7441FF !important',
  },
}))(IconButton);

const StyledSvgIcon = withStyles(() => ({
  root: {
    width: '17px',
    height: '22px',
  },
}))(AppSvgIcon);

export const TopUpButton: React.FC<IconButtonProps> = (props) => {
  const { title } = props;
  return (
    <div>
      <PurpleStyledIconButton {...props}>
        <StyledSvgIcon
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="22"
          viewBox="0 0 17 22"
          fill="none"
        >
          <rect width="56" height="56" rx="28" fill="#7441FF" />
          <path
            d="M14.6593 2.53523C15.0498 2.14471 15.0498 1.51154 14.6593 1.12102C14.2687 0.730494 13.6356 0.730494 13.245 1.12102L14.6593 2.53523ZM2.04836 12.7319C2.04836 13.2842 2.49607 13.7319 3.04836 13.7319L12.0484 13.7319C12.6006 13.7319 13.0484 13.2842 13.0484 12.7319C13.0484 12.1796 12.6006 11.7319 12.0484 11.7319L4.04836 11.7319L4.04836 3.73192C4.04836 3.17963 3.60064 2.73192 3.04836 2.73192C2.49607 2.73192 2.04836 3.17963 2.04836 3.73192L2.04836 12.7319ZM13.245 1.12102L2.34125 12.0248L3.75546 13.439L14.6593 2.53523L13.245 1.12102Z"
            fill="white"
          />
          <path
            d="M1.64648 20.1758L15.3534 20.1758"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </StyledSvgIcon>
      </PurpleStyledIconButton>
      <div style={{ marginTop: '6px', textAlign: 'center' }}>{title}</div>
    </div>
  );
};

export const BuyButton: React.FC<IconButtonProps> = (props) => {
  const { title } = props;
  return (
    <div>
      <StyledIconButton {...props}>
        <StyledSvgIcon
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
        >
          <path
            d="M10.4917 1.66797V20.3346"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M19.8335 11.0039L1.16683 11.0039"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </StyledSvgIcon>
      </StyledIconButton>
      <div style={{ marginTop: '6px', textAlign: 'center' }}>{title}</div>
    </div>
  );
};

export const SendButton: React.FC<IconButtonProps> = (props) => {
  const { title } = props;
  return (
    <div>
      <StyledIconButton {...props}>
        <StyledSvgIcon width="56" height="56" viewBox="0 0 56 56" fill="none">
          <rect width="56" height="56" rx="28" fill="#1E1D36" />
          <path
            d="M27.2929 40.7071C27.6834 41.0976 28.3166 41.0976 28.7071 40.7071L35.0711 34.3431C35.4616 33.9526 35.4616 33.3195 35.0711 32.9289C34.6805 32.5384 34.0474 32.5384 33.6569 32.9289L28 38.5858L22.3431 32.9289C21.9526 32.5384 21.3195 32.5384 20.9289 32.9289C20.5384 33.3195 20.5384 33.9526 20.9289 34.3431L27.2929 40.7071ZM28.7071 16.6133C28.3166 16.2228 27.6834 16.2228 27.2929 16.6133L20.9289 22.9773C20.5384 23.3678 20.5384 24.001 20.9289 24.3915C21.3195 24.782 21.9526 24.782 22.3431 24.3915L28 18.7347L33.6569 24.3915C34.0474 24.782 34.6805 24.782 35.0711 24.3915C35.4616 24.001 35.4616 23.3678 35.0711 22.9773L28.7071 16.6133ZM29 40L29 17.3205L27 17.3205L27 40L29 40Z"
            fill="white"
          />
        </StyledSvgIcon>
      </StyledIconButton>
      <div style={{ marginTop: '6px', textAlign: 'center' }}>{title}</div>
    </div>
  );
};

export const SellGoldButton: React.FC<IconButtonProps> = (props) => {
  const { title } = props;
  return (
    <div>
      <StyledIconButton {...props}>
        <StyledSvgIcon
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="2"
          viewBox="0 0 21 2"
          fill="none"
        >
          <path
            d="M19.8335 1L1.16682 0.999999"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </StyledSvgIcon>
      </StyledIconButton>
      <div style={{ marginTop: '6px', textAlign: 'center' }}>{title}</div>
    </div>
  );
};

export const SellButton: React.FC<IconButtonProps> = (props) => {
  const { title } = props;
  return (
    <div>
      <StyledIconButton {...props}>
        <StyledSvgIcon
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
        >
          <path
            d="M1.7396 10.0169C1.48576 10.2708 1.48576 10.6823 1.7396 10.9362C1.99344 11.19 2.405 11.19 2.65884 10.9362L1.7396 10.0169ZM12.1657 1.16012C12.1657 0.80114 11.8746 0.510125 11.5157 0.510124L5.66566 0.510125C5.30667 0.510124 5.01566 0.801139 5.01566 1.16012C5.01566 1.51911 5.30667 1.81012 5.66566 1.81012H10.8657V7.01012C10.8657 7.36911 11.1567 7.66012 11.5157 7.66012C11.8746 7.66012 12.1657 7.36911 12.1657 7.01012L12.1657 1.16012ZM2.65884 10.9362L11.9753 1.61974L11.056 0.700505L1.7396 10.0169L2.65884 10.9362Z"
            fill="white"
          />
          <path
            d="M1 16.8359L12.7115 16.8359"
            stroke="white"
            stroke-width="1.3"
            stroke-linecap="round"
          />
        </StyledSvgIcon>
      </StyledIconButton>
      <div style={{ marginTop: '6px', textAlign: 'center' }}>{title}</div>
    </div>
  );
};
