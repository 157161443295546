import React from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { LineChart } from '@shared/components/line-chart/line-chart';
import { Loading } from '@shared/components/loading';
import { ChartControls } from '@shared/components/new-design/chart-controls/chart-controls';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { useQuery } from 'react-query';

import { PortfolioChartViewModel } from './portfolio-chart.vm';

import { styles } from './portfolio-chart.styles';

interface Props extends AppWithStyles<typeof styles> {}

const PortfolioChartComponent: React.FC<Props> = appObserver(({ classes: c }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.laptop));

  const $vm = React.useMemo(() => new PortfolioChartViewModel(isMobile), []);

  const downloadPortfolioDataQuery = useQuery(
    ['download-portfolio-data', $vm.currency, $vm.currentPeriod],
    () => $vm.downloadPortfolioData(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  return (
    <div className={c.container}>
      <div className={c.chartHeader}>
        <h3 className={c.h2}>{t`Account Value Over Time`}</h3>
      </div>
      <div className={c.box}>
        {downloadPortfolioDataQuery.isFetching && (
          <div className={classNames(c.loader, 'loader')}>
            <Loading size={30} />
          </div>
        )}
        {!downloadPortfolioDataQuery.isFetching && (
          <LineChart options={$vm.chartOptions} data={$vm.chartData} />
        )}
      </div>
      <div className={c.controls}>
        <ChartControls
          currentPeriod={$vm.currentPeriod}
          periodOptions={$vm.periodOptions}
          updatePeriod={$vm.updatePeriod}
        />
      </div>
    </div>
  );
});

export const PortfolioChart = appWithStyles(styles)(PortfolioChartComponent);
