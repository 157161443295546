import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    titleBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      color: '#57566A',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.065px',
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'center',
    },
    currencySwitcher: {
      width: '67px',
      marginLeft: '10px',
    },
    totalAmount: {
      display: 'flex',
      color: '#FFF',
      fontSize: '43px',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '0.215px',
      marginTop: '22px',
    },
    amount: {},
    currency: {
      marginLeft: '10px',
    },
    percentage: {
      color: '#F7F9FD',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '16px',
      textTransform: 'uppercase',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-evenly',
      marginTop: '33px !important',
    },
    percentageItem: {
      display: 'flex',
      '& img': {
        width: '20px',
      },
    },
    productName: {},
    percent: {
      marginLeft: '5px',
    },
  });
}
