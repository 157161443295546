import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { TimeRangeType } from '@shared/enums/time-range-type.enum';
import { ReportPortfolioModel } from '@shared/models/orders/portfolio-reports-list';
import { ProductType } from '@shared/models/products/product-model';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { IOrdersService } from '@shared/types/orders-service';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesService } from '@shared/types/rates-service';
import { IRatesVM } from '@shared/types/rates-vm';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { IUsersService } from '@shared/types/users-service';
import { IWalletsService } from '@shared/types/wallets-service';
import { transformToBaseCurrency } from '@shared/utils/currency-pipe';
import { BigNumber } from 'bignumber.js';

export class DashboardViewModel {
  private ratesService = appInject<IRatesService>(DI_TOKENS.ratesService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  private ordersService = appInject<IOrdersService>(DI_TOKENS.ordersService);
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private smartCoinTracinkService = appInject<ISmartCoinTrackingService>(
    DI_TOKENS.smartCoinTrackingService,
  );
  private _wallets = {
    data: {} as Array<WalletsListModel>,
  };
  private _totalAmount = {
    data: '0.00' as string,
  };
  private _maintenanceMode = {
    isActive: false as boolean,
  };
  private _portfolioData: ReportPortfolioModel = new ReportPortfolioModel({
    investmentsRatio: {},
    historicData: [],
  });
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);

  get isActiveMaintenanceMode() {
    return this._maintenanceMode.isActive;
  }

  get activeCurrency() {
    return this.ratesVM.currency;
  }

  subscribeForOrders(cb: () => void) {
    this.smartCoinTracinkService.subscribeForExecutedOrder('mobileDashboard', cb);
  }

  setActiveCurrency(newValue: string) {
    this.ratesVM.currency = newValue as Currency;
  }

  get rate(): number {
    return this.ratesVM.rate[this.activeCurrency] as number;
  }

  get currencies() {
    return this.ratesService.availableCurrencies.map((currency) => ({
      label: transformToBaseCurrency(currency),
      value: currency,
    }));
  }

  get wallets() {
    return this._wallets.data;
  }

  get isNeedKYC() {
    return this.usersService.isNeedKYC;
  }

  getProductPriorityByCrrency(curreny: string) {
    const product = this.productsVM.getProductByCurrency(curreny);
    return product ? product.asJson.viewPriority : 0;
  }

  get products() {
    return this.productsVM.products;
  }

  get investmentsRatio(): Array<{ currency: string; percentage: number }> {
    const productsNames = this.productsVM.products
      .sort((prev, next) => (next.asJson.type === ProductType.SMART_COIN ? 1 : -1))
      .map((p) => p.asJson.name);
    return productsNames.map((productName) => {
      const ratio = this._portfolioData.asJson.investmentsRatio?.hasOwnProperty(productName)
        ? Number(this._portfolioData.asJson.investmentsRatio[productName])
        : 0;
      return {
        currency: productName,
        percentage: ratio,
      };
    });
  }

  get totalAmount() {
    return this._totalAmount.data;
  }

  constructor() {
    appMakeObservable(this, {
      _wallets: appObservable,
      _portfolioData: appObservable,
      _totalAmount: appObservable,
      _maintenanceMode: appObservable,
    });
  }

  downloadWallets = async () => {
    this._wallets.data = await this.walletsService.getList(Currency.USDT, true);
    this.calculateTotalAmount();
  };

  async downloadPortfolioData() {
    this._portfolioData = await this.ordersService.getPortfolioChart(
      TimeRangeType.Day,
      this.ratesVM.currency,
    );
  }

  async fetchMaintenanceModeStatus() {
    await this.usersService.fetchMaintenanceModeStatus();
    this._maintenanceMode.isActive = this.usersService.isActiveMaintenanceMode;
  }

  calculateTotalAmount() {
    let totalAmountInFiatCurrencyBigNumber = BigNumber(0);
    let totalAmountInBaseFiatCurrencyBigNumber = BigNumber(0);

    this._wallets.data.forEach((wallet) => {
      totalAmountInFiatCurrencyBigNumber = totalAmountInFiatCurrencyBigNumber.plus(
        wallet.asJson.balanceInFiatCurrency,
      );
      totalAmountInBaseFiatCurrencyBigNumber = totalAmountInBaseFiatCurrencyBigNumber.plus(
        wallet.asJson.balanceInBaseFiatCurrency,
      );
    });

    this._totalAmount.data =
      this.activeCurrency === Currency.USDT
        ? minDigitsAfterDot(
            amountPipe(parseFloat(totalAmountInFiatCurrencyBigNumber.toFixed(2))),
            2,
          )
        : minDigitsAfterDot(
            amountPipe(parseFloat(totalAmountInBaseFiatCurrencyBigNumber.toFixed(2))),
          );
  }

  isFiatWallet(wallet: WalletsListModel) {
    return (
      this.productsVM.getProductTypeByCurrency(wallet.asJson.currency) === ProductType.FIAT_COIN
    );
  }
}
