import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import dateIcon from '@shared/icons/transactions/date-icon.svg';
import failedIcon from '@shared/icons/transactions/failed-icon.svg';
import inProgressIcon from '@shared/icons/transactions/in-progress-icon.svg';
import cachedOutIcon from '@shared/icons/transactions/success-cached-out.svg';
import successDepositedIcon from '@shared/icons/transactions/success-deposited.svg';
import successPurchasedIcon from '@shared/icons/transactions/success-purchased.svg';
import successReceivedIcon from '@shared/icons/transactions/success-received.svg';
import successSentIcon from '@shared/icons/transactions/success-sent.svg';
import { Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    item: {
      padding: '20px 0px 10px 0px',
      borderBottom: `1px solid rgba(238, 238, 238, 0.06)`,
      '&:last-of-type': {
        marginBottom: '46px',
      },
      [breakpoints.up(Layout.laptop)]: {
        '&:last-of-type': {
          marginBottom: '0px',
        },
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 13,

      '& + &': {
        marginTop: '10px',
      },
    },
    status: {
      display: 'inline-block',
      paddingLeft: 30,
      fontSize: '15px',
      fontWeight: 500,
      color: '#fff',
      textTransform: 'capitalize',
    },
    date: {
      display: 'inline-block',
      background: `url(${dateIcon}) no-repeat 5px center`,
      paddingLeft: 30,
      color: '#79818F',
      fontSize: '15px',
      fontWeight: 500,
    },
    progress: {
      background: `url(${inProgressIcon}) no-repeat left center`,
    },
    fail: {
      background: `url(${failedIcon}) no-repeat left center`,
    },
    successPurchased: {
      background: `url(${successPurchasedIcon}) no-repeat left center`,
    },
    successSent: {
      background: `url(${successSentIcon}) no-repeat left center`,
    },
    successReceived: {
      background: `url(${successReceivedIcon}) no-repeat left center`,
    },

    successDeposited: {
      background: `url(${successDepositedIcon}) no-repeat left center`,
    },
    successCachedOut: {
      background: `url(${cachedOutIcon}) no-repeat left center`,
    },
    debit: {
      fontWeight: 500,
      color: '#1FAE70',
      textAlign: 'right',
      fontSize: '15px',
    },
    credit: {
      fontWeight: 500,
      color: '#FF2C2C',
      textAlign: 'right',
      fontSize: '15px',
    },
  });
}
