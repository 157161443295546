import React, { useEffect, useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { PortfolioChart } from '@modules/new-private/dashboard/components/portfolio-chart/portfolio-chart';
import { RecentActivities } from '@modules/new-private/dashboard/components/recent-activities/recent-activities';
import { Loading } from '@shared/components/loading';
import WalletsCarousel from '@shared/components/wallets-carousel/wallets-carousel';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { transformToBaseCurrency } from '@shared/utils/currency-pipe';
import { BigNumber } from 'bignumber.js';
import { reaction } from 'mobx';

import { DashboardViewModel } from './dashboard.vm';

import { styles } from './dashboard.styles';

export interface DashboardProps extends AppWithStyles<typeof styles> {}

const Dashboard: React.FC<DashboardProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new DashboardViewModel(), []);

  useEffect(() => {
    $vm.getBlockchainWallet();
    reaction(
      () => $vm.currency,
      () => $vm.getWallets(),
    );
    return () => {
      $vm.releaseNotifications();
    };
  }, []);

  useEffect(() => {
    $vm.subscribeForOrders(() => {
      $vm.getWallets();
    });
  }, []);

  if ($vm.loading.wallets || $vm.loading.restrictions) {
    return <Loading size={70} />;
  }

  let accountBalance = 0;
  if ($vm.currency === Currency.AUDT) {
    accountBalance = parseFloat(
      $vm.wallets
        .reduce((sum: BigNumber, wallet: WalletsListModel) => {
          sum = sum.plus(wallet.asJson.balanceInBaseFiatCurrency);
          return sum;
        }, BigNumber(0))
        .toFixed(2, BigNumber.ROUND_CEIL),
    );
  } else if ($vm.currency === Currency.USDT) {
    accountBalance = parseFloat(
      $vm.wallets
        .reduce((sum: BigNumber, wallet: WalletsListModel) => {
          sum = sum.plus(wallet.asJson.balanceInFiatCurrency);
          return sum;
        }, BigNumber(0))
        .toFixed(2, BigNumber.ROUND_CEIL),
    );
  }

  return (
    <div className={classes.root}>
      <WalletsCarousel
        wallets={$vm.wallets}
        onSelectWallet={() => {}}
        totalAmount={`${t`Account balance`} ${transformToBaseCurrency(
          $vm.currency,
        )} ${minDigitsAfterDot(amountPipe(accountBalance, 2))}`}
      />
      <div className={classes.chart}>
        <div className={classes.box}>
          <PortfolioChart />
        </div>
        <div className={classes.column}>
          <div className={classes.recentActivities}>
            <RecentActivities />
          </div>
        </div>
      </div>
    </div>
  );
});

export default appWithStyles(styles)(Dashboard);
