import React, { useEffect, useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import AccountValue from '@modules/new-private/dashboard/components/account-value/account-value';
import Actions from '@modules/new-private/dashboard/components/actions/actions';
import { PortfolioChart } from '@modules/new-private/dashboard/components/portfolio-chart/portfolio-chart';
import { RecentActivities } from '@modules/new-private/dashboard/components/recent-activities/recent-activities';
import { DashboardViewModel } from '@modules/new-private/dashboard/dashboard.vm';
import { Loading } from '@shared/components/loading';
import { MaintenanceModeLabel } from '@shared/components/new-design/maintenance-mode-label';
import WalletsCarousel from '@shared/components/new-design/wallets-carousel/wallets-carousel';
import { useQuery } from 'react-query';

import { styles } from './mobile-dashboard.styles';

export interface MobileDashboardProps extends AppWithStyles<typeof styles> {}

const MobileDashboard: React.FC<MobileDashboardProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new DashboardViewModel(), []);

  const downloadWalletsQuery = useQuery(['download-wallets-list'], () => $vm.downloadWallets(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    refetchIntervalInBackground: false,
  });

  const downloadPortfolioDataQuery = useQuery(
    ['download-portfolio-data'],
    () => $vm.downloadPortfolioData(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  const fetchMaintenanceModeStatusQuery = useQuery(
    ['fetch-maintenance-mode-status'],
    () => $vm.fetchMaintenanceModeStatus(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  useEffect(() => {
    $vm.subscribeForOrders(() => {
      downloadWalletsQuery.refetch();
      downloadPortfolioDataQuery.refetch();
    });
  }, []);

  if (
    downloadWalletsQuery.isLoading ||
    downloadPortfolioDataQuery.isLoading ||
    fetchMaintenanceModeStatusQuery.isLoading ||
    !$vm.wallets.length
  ) {
    return <Loading size={70} />;
  }

  return (
    <div className={classes.root}>
      {$vm.isActiveMaintenanceMode ? (
        <div className={classes.maintenanceLabel}>
          <MaintenanceModeLabel />
        </div>
      ) : (
        <></>
      )}
      <AccountValue vm={$vm} />
      <WalletsCarousel wallets={$vm.wallets} onSelectWallet={() => {}} />
      <Actions wallets={$vm.wallets} vm={$vm} />
      <PortfolioChart />
      <RecentActivities />
    </div>
  );
});

export default appWithStyles(styles)(MobileDashboard);
